export const PRODUCTS = [
    [
        {
            name: '3i Touchpoint Analytics',
            address: 'touchpointanalytics'
        },
        {
            name: '3i DataPlexus',
            address: 'data-plexus'
        },
        {
            name: 'DSS Lab STRICE',
            address: 'dsslabstrice'
        }
    ],
    [
        {
            name: '3i Search Platform',
            address: 'search-platform'
        },
        {
            name: '3i Search Platform 3.x',
            address: 'search-platform-3'
        },
        {
            name: '3i Big Data Analytics Processing Platform',
            address: 'dpp-platform'
        },
        {
            name: '3i NLP Platform',
            address: 'nlp-platform'
        },
        {
            name: '3i Crawler 2.0',
            address: 'crawler'
        }
        // {
        //     name: '3i ADPI',
        //     address: '3i-adpi'
        // }
    ],
    [
        {
            name: '3i Speaker ID SDK',
            address: 'speaker-id'
        },
        {
            name: '3i Speaker ID SDK 3.x',
            address: 'speaker-id-3'
        },
        {
            name: '3i Gender ID SDK',
            address: 'gender-id'
        },
        {
            name: '3i Speech Detector SDK',
            address: 'speech-detector'
        },
        {
            name: '3i Tone Detector SDK',
            address: 'speech-tone-detector'
        },
        {
            name: '3i Speech Transcriptor',
            address: 'speech-transcriptor'
        },
        {
            name: '3i Speech Diarization SDK',
            address: 'speech-diar'
        },
        {
            name: '3i Language ID',
            address: 'lang-id'
        },
        {
            name: '3i Text To Speech',
            address: 'text-to-speech'
        }
    ], [{
        name: 'ПМ ЭОС',
        address: 'speech-service'
    }]
    // [
    //     {
    //         name: '3i NewSens',
    //         address: 'newsens'
    //     }
    // ],
    // [
    //     {
    //         name: '3i Voice Capture Box 2.x',
    //         address: 'voice-capture-box'
    //     }
    // ]
]
