export default function SpeechService () {
    return (
        <div>
            <p className='description__title'>ПМ ЭОС</p>
            <div className='description__section'>
                <p className='description__cursive-text'>
                    Программный  модуль разработан при поддержке Федерального государственного бюджетного учреждения
                    «Фонд содействия развитию малых форм предприятий в научно-технической сфере» в рамках федерального
                    проекта «Искусственный интеллект» национальной программы «Цифровая экономика Российской Федерации»
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                Интеллектуальный программный модуль оценки эмоционального состояния диктора для поддержки принятия
                решений в контакт-центрах, на основе технологий искусственного интеллекта по интерпретируемой обработки
                данных и распознаванию речи (ПМ ЭОС) предназначен для обеспечения автоматической оценки эмоционального
                состояния диктора по акустическим признакам звукового сигнала, содержащего речевое сообщение;
                автоматического распознавания эмоции по расшифровке входного речевого сообщения и поддержки интегральной
                оценки эмоции по акустическим признакам и текстовым признакам речевого сообщения.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Реализация
                </p>
                <p className='description__text'>
                    ПМ ЭОС состоит из одного модуля 3i-speech-service и реализовано в виде серверного приложения,
                    запускаемого в среде Docker.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Системные требования (минимальные):
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        CPU Intel(R) Xeon(R) CPU E5-2620 v4 @ 2.10GHz;
                    </li>
                    <li className='description__list-item'>
                        16 ГБ ОЗУ;
                    </li>
                    <li className='description__list-item'>
                        64 ГБ дискового пространства;
                    </li>
                    <li className='description__list-item'>
                        Linux x64.
                    </li>
                </ul>
                <p className='description__text'>
                    Функционирование ПМ ЭОС осуществляется на серверах или рабочих станциях,
                    работающих на архитектуре x86_64 с поддержкой AVX2 инструкций.
                </p>
                <p className='description__text'>
                    ПМ ЭОС может опционально использовать графические процессоры NVIDIA (не хуже NVIDIA P4000),
                    с объёмом памяти не меньше 8 ГБ.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>Стоимость ПО определяется на основе технических требований.
                    Для расчета стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/PM_EOS.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
