export default function Crawler () {
    return (
        <div className='description'>
            <p className='description__title'>3i Crawler 2.0</p>
            <div className='description__section'>
                <p className='description__text'>
                    <b>3i Crawler</b> - программный комплекс для автоматизации сбора разнородной информации из
                    различных источников. Позволяет создавать и обучать поисковых роботов. Имеет встроенные средства
                    для описания структуры сайтов с помощью интуитивно-понятного графического интерфейса, что дает
                    возможность сотрудникам, не имеющим опыта программирования, в полной мере использовать
                    функционал комплекса.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>
                    Функциональные возможности:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Эмуляция различных моделей поведения интернет-пользователей;
                    </li>
                    <li className='description__list-item'>
                        Работа с сайтами произвольной структуры;
                    </li>
                    <li className='description__list-item'>
                        Работа на защищенных сайтах;
                    </li>
                    <li className='description__list-item'>
                        Интегрированные средства контроля за эффективностью работы и состоянием поисковых роботов.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>
                    Состав решения:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        3i Crawler Design Studio &ndash; приложение для разработки и отладки роботов. ПО
                        представляет собой интегрированную среду разработки и тестирования (IDE), содержит все
                        инструменты, необходимые для проектирования роботов и структур;
                    </li>
                    <li className='description__list-item'>
                        3i Crawler Server &ndash; Сервис для управления сбором информации и планировщик заданий;
                    </li>
                    <li className='description__list-item'>
                        3i Crawler Manager &ndash; Приложение для удаленной настройки и управления службой 3i
                        Crawler Server.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>
                    Системные требования
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        CPU: Intel x86/x64 2ГГц и выше;
                    </li>
                    <li className='description__list-item'>
                        RAM: 2Гб;
                    </li>
                    <li className='description__list-item'>
                        HDD: от 300Mb, 7200 об/мин или RAID-массив.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Поддерживаемые ос:</p>
                <ul className='description__list'>
                    <li className='description__list-item'>Windows 7 x86/x64,</li>
                    <li className='description__list-item'>Windows 8 x86/x64,</li>
                    <li className='description__list-item'>Windows Server 2008 или выше,</li>
                    <li className='description__list-item'>Linux 32/64-bit.</li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__text'>
                    Для работы с программой не требуется знаний программирования. Для повышения качества результатов
                    работы желательно владение базовыми знаниями языка гипертекстовой разметки HTML.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3iCrawler.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
