export default function DssLabStrice () {
    return (
        <div className='description'>
            <p className='description__title'>DSS Lab STRICE</p>
            <div className='description__section'>
                <p className='description__text'>
                    Система ситуационного моделирования <b>DSS Lab STRICE</b> применяется для анализа и
                    прогнозирования развития сложных слабоструктурированных объектов и ситуаций. К
                    слабоструктурированным относятся объекты и ситуации, для описания которых используются не
                    только количественные, но и качественные характеристики. В основу работы программы положен
                    метод моделирования на основе когнитивных карт, предполагающий создание модели, представляющей
                    собой структуру причинно-следственных связей между базисными факторами ситуации (когнитивную
                    карту), - факторами в наибольшей степени определяющими ее развитие. DSS Lab STRICE обеспечивает
                    выполнение следующих основных функций:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>создание тезауруса;</li>
                    <li className='description__list-item'>построение ситуационных моделей;</li>
                    <li className='description__list-item'>структурный анализ моделей;</li>
                    <li className='description__list-item'>сценарное моделирование.</li>
                </ul>
                <p className='description__text'>
                    Тезаурус представляет собой структурированный перечень факторов, характеризующих определенную
                    ситуацию или предметную область.
                </p>

                <p className='description__text'>
                    Создание качественных ситуационных моделей возможно как в графовой, так и в табличной
                    (матричной) формах.
                </p>
                <p className='description__text'>
                    Структурный анализ моделей заключается в анализе целей на противоречивость, анализе управлений
                    на согласованность с целям и анализе эффективности интегрального влияния управлений на цели.
                </p>
                <p className='description__text'>
                    Сценарное моделирование развития ситуации возможно в режиме саморазвития, предполагающем
                    сохранение существующих тенденций факторов и, по сути, представляющего собой экстраполяцию
                    текущей ситуации с учетом взаимных влияний факторов; в режиме управляемого развития,
                    предполагающем целенаправленное воздействие на один или несколько факторов модели.
                </p>
                <p className='description__text'>
                    По результатам моделирования возможно создание отчетов в формате MS Word.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/DSSStrice.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
