import {NavLink} from 'react-router-dom'
import {PRODUCTS} from '../../utils/variables'

export default function ProductsList() {
    return (
        <section>
            {PRODUCTS.map(group => {
                return (
                    <ul className='products__list' key={Math.random()}>
                        {group.map(item => {
                            return (
                                <li className='products__item' key={item.address}>
                                    <NavLink to={`/products/${item.address}`} className='products__link'>
                                        {item.name}
                                    </NavLink>
                                </li>
                            )
                        })}
                    </ul>
                )
            })}
        </section>
    )
}
