export default function TouchPointAnalytics () {
    return (
        <div className='description'>
            <p className='description__title'>3i Touchpoint Analytics</p>
            <p className='description__more-info'>БОЛЕЕ ПОДРОБНАЯ ИНФОРМАЦИЯ О ПРОДУКТЕ ДОСТУПНА ПО ССЫЛКЕ:</p>
            <a
                className='description__more-info-link'
                href='https://touchpoint-analytics.ru/'
            >
                https://touchpoint-analytics.ru/
            </a>
            <p className='description__text'>
                <b>3i Touchpoint Analytics</b> &ndash; платформа речевой аналитки для автоматизированного анализа
                вербальных коммуникаций между сотрудниками и клиентами в любых точках
                контакта:от кассы в торговом зале до контакт-центра
            </p>
            <p className='description__reference'>
                В качестве входных данных может использоваться как запись с внешних микрофонов, смонтированных в
                местах контакта сотрудников с клиентами, например, на кассах в торговом зале или в операционном зале
                банковского отделения, так и записи телефонных разговоров из контакт-центра.
            </p>
            <div className='description__section'>
                <p className='description__text'>
                    <b>3i Touchpoint Analytics</b> помогает повысить качество обслуживания, проконтролировать
                    соблюдение стандартов и более эффективно управлять KPI сотрудников.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Решаемые задачи:</p>
                <ul className='description__list'>
                    <li className='description__list-item'>Мониторинг соблюдения сценариев продаж;</li>
                    <li className='description__list-item'>Непрерывный контроль качества обслуживания клиентов;</li>
                    <li className='description__list-item'>Управление KPI для торгового персонала;</li>
                    <li className='description__list-item'>Сокращение расходов на тайных покупателей;</li>
                    <li className='description__list-item'>Проверка маркетинговых гипотез;</li>
                    <li className='description__list-item'>Повышение конверсии общения в продажи;</li>
                    <li className='description__list-item'>Повышение качества измерений клиентской лояльности.</li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Интеллектуальный анализ позволяет эффективно выявлять:</p>
                <ul className='description__list'>
                    <li className='description__list-item'>Отклонения от скрипта;</li>
                    <li className='description__list-item'>Использование недопустимой лексики;</li>
                    <li className='description__list-item'>Причины недовольства клиентов;</li>
                    <li className='description__list-item'>Поведенческие паттерны;</li>
                    <li className='description__list-item'>Проблемные ситуации.</li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Преимущества решения:</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Высокое качество распознавания речи в зашумленной среде;
                    </li>
                    <li className='description__list-item'>
                        Полный цикл: от сбора и анализа данных до отчетности;
                    </li>
                    <li className='description__list-item'>
                        Гибко настраиваемые шаблоны отчетов;
                    </li>
                    <li className='description__list-item'>
                        Мощные инструменты анализа статистики и текстовых данных;
                    </li>
                    <li className='description__list-item'>
                        Возможности гибкой настройки отчетов под требования конечных пользователей;
                    </li>
                    <li className='description__list-item'>
                        Гибкие настройки отраслевых категорий;
                    </li>
                    <li className='description__list-item'>
                        Возможность развертывания системы на инфраструктуре заказчика (on-premise);
                    </li>
                    <li className='description__list-item'>
                        Неограниченная масштабируемость.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Технические особенности:</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Высокая точность распознавания слитной речи;
                    </li>
                    <li className='description__list-item'>
                        Автоматическое разделение речи на сотрудника и покупателя;
                    </li>
                    <li className='description__list-item'>
                        Возможность добавлять словари, отражающие специфику компании заказчика и конкретной отрасли;
                    </li>
                    <li className='description__list-item'>
                        Микрофоны оптимизированы для работы в условиях неоднородного акустического окружения с
                        высоким уровнем посторонних шумов;
                    </li>
                    <li className='description__list-item'>
                        Автоматическое выделение типовых шумов для повышения точности распознавания;
                    </li>
                    <li className='description__list-item'>
                        Неограниченное кол-во точек съема звука.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Эффекты от внедрения:</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Повышение качества контроля стандартов обслуживания;
                    </li>
                    <li className='description__list-item'>
                        Оптимизация затрат на супервайзинг;
                    </li>
                    <li className='description__list-item'>
                        Увеличение уровня лояльности клиентов за счет повышения качества обслуживания;
                    </li>
                    <li className='description__list-item'>
                        Оптимизация политик коммуникаций и управления персоналом;
                    </li>
                    <li className='description__list-item'>
                        Повышение эффективности планирования маркетинговых кампаний.
                    </li>
                </ul>
            </div>
        </div>
    )
}
