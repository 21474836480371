import {Route, Routes, Navigate} from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'
import Main from './Main'
import Products from './Products/Products'

export default function App () {
    return <div className='app'>
        <Header/>
        <Routes>
            <Route path='/' element={<Main/>}/>
            <Route path='/products/*' element={<Products/>}/>
            <Route path='*' element={<Navigate to='/'/>}/>
        </Routes>
        <Footer/>
    </div>
}
