export default function SpeakerIdSDK3 () {
    return (
        <div className='description'>
            <p className='description__title'>3i Speaker ID SDK 3.x</p>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                    Библиотека 3i Speaker ID предназначена для разработки программного обеспечения, в котором
                    необходимо решать задачу автоматической текстонезависимой идентификации личности диктора по
                    голосу, передающемуся, как по телевизионным каналам, так и по каналам телефонной связи.
                </p>
                <p className='description__text'>
                    3i Speaker ID SDK используется в качестве программного обеспечения, предоставляя разработчику
                    соответствующий функционал API.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технология</p>
                <p className='description__text'>
                    Технология идентификации основана на применении глубоких нейронных сетей, при помощи которых по
                    речевому сигналу генерируется вектор голосовых признаков. Для получения вектора диктора
                    достаточно 5-ти секунд речи. Полученный вектор представляет собой модель голоса, состоит из
                    действительных чисел, размерность вектора 112 элементов.
                </p>
                <p className='description__text'>
                    Для решения задач идентификации в библиотеке предусмотрена функция сравнения двух векторов при
                    помощи косинусной меры близости. Для принятия решений рекомендуется использовать пороговое
                    значение косинусной близости, при котором достигается минимальная суммарная ошибка.
                </p>
                <p className='description__text'>
                    В библиотеку встроен детектор речи, позволяющий эффективно &laquo;отсеивать&raquo; неречевые
                    составляющие входного сигнала.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Модификации</p>
                <p className='description__text'>
                    Существует две модификации продукта, ориентированных на различные источники входных данных.
                    Модификации различаются индексом:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Модификация 3.0 - обработка речевых данных из телефоного канала;
                    </li>
                    <li className='description__list-item'>
                        Модификация 3.1 - обработка речевых данных из телерадиовещательного канала.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технические характеристики</p>
                <p className='description__text'>Требования к входным аудио данным 3i Speaker ID SDK:</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Возможные источники: WAV-файлы, буфер отсчётов;
                    </li>
                    <li className='description__list-item'>
                        Частота дискретизации сигнала: 8 кГц;
                    </li>
                    <li className='description__list-item'>
                        Разрядность квантования: 16-бит;
                    </li>
                    <li className='description__list-item'>
                        Тип кодирования, если источником является WAV-файл: A-закон, Mu-закон или PCM (без
                        дополнительных блоков типа list или fact);
                    </li>
                    <li className='description__list-item'>
                        Тип кодирования, если источником является буфер памяти: PCM.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Требования к качеству сигнала:</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Значение ОСШ должно составлять не менее 10дБ;
                    </li>
                    <li className='description__list-item'>
                        Допускается присутствие посторонних звуков в виде однотональных гудков и сигналов тонального
                        набора, DTMF.
                    </li>
                </ul>
                <p className='description__text'>
                    Выходные данные: число с плавающей точкой confidence, отражающее достоверность того, что
                    идентифицируемый диктор является &laquo;целевым&raquo;. Значение достоверности имеет диапазон
                    [-1;1].
                </p>
                <p className='description__text'>
                    <b>EER=3.39%</b>
                </p>
                <p className='description__text'>
                    Равновероятная ошибка между ошибками первого и второго рода достигается при пороговом значении
                    на достоверность равном 0.72
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Реализация
                </p>
                <p className='description__text'>
                    SDK реализован в виде динамической библиотеки (DLL), написанной на языке С++. Допускается
                    использование в нескольких параллельных потоках.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Системные требования</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        CPU Intel Core i7 &ndash; 5820K 3.3 ГГц (6 физических вычислительных ядер);
                    </li>
                    <li className='description__list-item'>
                        ОЗУ 16 ГБ;
                    </li>
                    <li className='description__list-item'>
                        100 Гб свободного места на жёстком диске;
                    </li>
                    <li className='description__list-item'>
                        OC Microsoft Windows 7 SP1 или выше с предустановленным пакетом Microsoft VC 2015
                        Redist (x64).
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Стоимость
                </p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Документация
                </p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <p>
                <a
                    className='description__download-link'
                    href='/files/3i_Speaker_ID_v3.x.pdf'
                    download
                >
                    Скачать документацию
                </a>
            </p>
        </div>
    )
}
