export default function SpeechDiarization () {
    return (
        <div className='description'>
            <p className='description__title'>
                3i Speech Diarization SDK
            </p>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Назначение
                </p>
                <p className='description__text'>
                    3i Speech Diarization SDK &ndash; встраиваемый программный модуль, реализованный в виде
                    динамически подгружаемой библиотеки, предназначен для разделения речевого сигнала по голосам
                    дикторов в аудио-потоке. Реализуемая в библиотеке технология может эффективно работать как с
                    телерадиовещательным, так и с телефонным каналом входящих данных. Данный SDK может
                    использоваться в качестве начального этапа обработки речевых сигналов в системах голосовой
                    биометрии и распознавания речи.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Технология разделения по голосам включает в себя решение
                    следующих задач:</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Определение точек смены голоса диктора;
                    </li>
                    <li className='description__list-item'>
                        Определение количества дикторов;
                    </li>
                    <li className='description__list-item'>
                        Определение, в каких именно фрагментах речевого сигнала говорит каждый найденный диктор.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Технология
                </p>
                <p className='description__text'>
                    Технология сегментации речевого потока реализована на многослойной нейронной сети
                    (DNN &ndash; от сокр. Deep Neural Network), обученной извлекать из краткосрочной спектральной
                    характеристики речевого сигнала признаки, характеризующие голос диктора.
                </p>
                <p className='description__text'>
                    Каждый такой вектор признаков называется &laquo;глубоким&raquo; вектором или d-вектором.
                    Расстояние между двумя такими векторами будет малым, если они принадлежат одному диктору, и
                    большим, если разным. Это свойство позволяет обнаружить точки смены говорящего, а
                    также &laquo;объединить&raquo; фрагменты, в которых присутствует голос одного диктора.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>
                    Модификации:
                </p>
                <p className='description__text'>
                    3i Speech Diarization SDK имеет 2 модификации:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Broadcast &ndash; модификация для сегментации голоса в телерадиовещательном речевом потоке;
                    </li>
                    <li className='description__list-item'>
                        Phone &ndash; модификация для сегментации голоса в телефонном речевом потоке.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Реализация
                </p>
                <p className='description__text'>
                    SDK реализован в виде DLL-библиотеки, разработанной с использованием языка С++. Обеспечивает
                    потокобезопасное встраивание функций диаризации речи в произвольные Windows-приложения.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>
                    Требования к входным аудио данным
                </p>
                <p className='description__text'>
                    Требование к аудио файлам:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Кодирование: несжатый WAV-PCM, A-Law или Mu-Law;
                    </li>
                    <li className='description__list-item'>
                        Квантование отсчёта: 16 бит (при кодировании &laquo;несжатый WAV-PCM&raquo;);
                    </li>
                    <li className='description__list-item'>
                        Частота дискретизации: 8000 Гц;
                    </li>
                    <li className='description__list-item'>
                        Тип файла: *.wav.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>
                    Требования к буферу памяти, в котором хранятся аудио данные:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>Кодирование: несжатый WAV-PCM;</li>
                    <li className='description__list-item'>Квантование отсчёта: 16 бит;</li>
                    <li className='description__list-item'>Частота дискретизации: 8000 Гц.</li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Преимущества</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Надёжность (за счет отсутствия необходимости взаимодействия с удаленным модулем);
                    </li>
                    <li className='description__list-item'>
                        Производительность (за счет использования кэша и подгрузки кода и данных в пространство
                        адресов приложения);
                    </li>
                    <li className='description__list-item'>
                        Прозрачность системы взаимодействия;
                    </li>
                    <li className='description__list-item'>
                        Легкость внесения изменений;
                    </li>
                    <li className='description__list-item'>
                        Масштабируемость.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>
                    Системные требования
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        CPU Intel Core i7 &ndash; 5820K 3.3 ГГц (6 физических вычислительных ядер);
                    </li>
                    <li className='description__list-item'>
                        ОЗУ 16 ГБ;
                    </li>
                    <li className='description__list-item'>
                        100 Гб свободного места на жёстком диске;
                    </li>
                    <li className='description__list-item'>
                        Microsoft Windows 7 x64 SP1 или выше с предустановленным пакетом Microsoft VC 2013 Redist
                        (x64);
                    </li>
                    <li className='description__list-item'>
                        Доступна версия под Linux 64-bit.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Документация
                </p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_Speech_Diar_SDK.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
