export default function GenderId () {
    return (
        <div className='description'>
            <p className='description__title'>3i Gender ID SDK</p>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                    Библиотека 3i Gender ID предназначена для разработки программного обеспечения, в котором
                    необходимо решать задачу автоматического определения пола диктора по речевым сообщениям,
                    передающимся как по телевизионным каналам, так и по каналам телефонной связи.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технология</p>
                <p className='description__text'>
                    Технология идентификации пола диктора по голосу основана на моделях из смесей гауссовых
                    распределений.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технические характеристики</p>
                <p className='description__list-title'>Точность идентификации</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        вероятность ошибки принять мужчину за женщину: 0,0096;
                    </li>
                    <li className='description__list-item'>
                        вероятность ошибки принять женщину за мужчину: 0,0357.
                    </li>
                </ul>
                <p className='description__text'>
                    Средний уровень ошибок при равном количестве мужчин и женщин оценивался на речевой базе, объёмом
                    более двух тысяч фонограмм и составил 1,63%.
                </p>
                <p className='description__text'>
                    Скорость обработки - в 150-200 раз быстрее времени звучания.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Тип обрабатываемого сигнала</p>
                <ul className='description__list'>
                    <li className='description__list-item'>WAV-файлы, буфер отсчётов;</li>
                    <li className='description__list-item'>частота дискретизации 8 кГц;</li>
                    <li className='description__list-item'>разрядность квантования 8 или 16-бит;</li>
                    <li className='description__list-item'>тип кодирования: A-закон, m-закон или PCM.</li>
                </ul>
                <p className='description__text'>
                    Требования к качеству речевого сигнала - отношение сигнал/шум не
                    менее 7 дБ.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Реализация</p>
                <p className='description__text'>
                    SDK реализован в виде DLL-библиотеки, написанной на языке С++, позволяющей потокобезопасное
                    встраивание функций автоматического определения пола в произвольные Windows-приложения.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Стоимость
                </p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_Gender_ID_SDK.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
