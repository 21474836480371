import {useEffect, useState} from 'react'

import wave from '../icons/Wave.png'
import poiskit from '../icons/poiskit.jpg'
import infocubes from '../icons/infocubes.jpg'
import buttonUp from '../icons/arrow-up-circle.png'

export default function Main () {
    const [isVisible, setIsVisible] = useState(false)

    const toggleVisibility = () => {
        if (window.scrollY > 600) setIsVisible(true)
        else setIsVisible(false)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility)
        return () => window.removeEventListener('scroll', toggleVisibility)
    }, [])

    return (
        <div className='page'>
            <h1 className='page__title'>Разработка и коммерциализация наукоемких продуктов</h1>
            <p className='page__subtitle'>Благодаря применению передовых и уникальных научных методов,
                качество разрабатываемых компанией продуктов соответствует мировому уровню
            </p>
            <img className='image-wave' alt='' src={wave} draggable='false'/>
            {/* <button className='button'>Узнать больше</button> */}
            <div className='sections'>
                <div id='about' className='section section_first'>
                    <div className='section__header'>
                        <div className='section__header-line'/>
                        <h2 className='section__header-title'>О компании</h2>
                    </div>
                    <div className='section__body'>
                        <div className='company-indicators'>
                            <div className='company-indicator'>
                                <p className='company-indicator__figure'>2006</p>
                                <p className='company-indicator__description'>Год основания DSS Lab</p>
                            </div>
                            <div className='company-indicator'>
                                <p className='company-indicator__figure'>&gt;15 лет</p>
                                <p className='company-indicator__description'>Исследований и разработок</p>
                            </div>
                            <div className='company-indicator'>
                                <p className='company-indicator__figure'>100%</p>
                                <p className='company-indicator__description'>Собственные технологии</p>
                            </div>
                            <div className='company-indicator'>
                                <p className='company-indicator__figure'>300+</p>
                                <p className='company-indicator__description'>Реализованных проектов</p>
                            </div>
                        </div>
                        <ul className='company-description'>
                            <li className='company-description__paragraph'>
                                Компания DSS Lab была учреждена в 2006 году выходцами из научной академической среды
                                с целью разработки и коммерциализации наукоемких продуктов в области поддержки
                                принятия решений и обработки неструктурированной информации.
                            </li>
                            <li className='company-description__paragraph'>
                                В настоящее время в компании работает коллектив профессиональных разработчиков
                                и исследователей, развивающих речевые технологии, технологии обработки
                                естественного языка, технологии поддержки принятия решений.
                            </li>
                            <li className='company-description__paragraph'>
                                Благодаря применению передовых и уникальных научных методов, качество
                                разрабатываемых компанией продуктов соответствует мировому уровню,
                                а для некоторых технологий даже его превосходит.
                            </li>
                        </ul>

                    </div>
                </div>
                <div id='partners' className='section'>
                    <div className='section__header'>
                        <div className='section__header-line'/>
                        <h2 className='section__header-title'>Партнеры</h2>
                    </div>
                    <div className='section__body'>
                        <div className='company-partners'>
                            <div className='company-partner'>
                                <img className='company-partner__logo' alt='' src={poiskit} draggable='false'/>
                                <p className='company-partner__description'>
                                    НТЦ “ПОИСК-ИТ” один из ведущих российских
                                    системных интеграторов в области информационно-аналитических систем и обработки
                                    неструктурированной информации.
                                </p>
                                <a
                                    className='company-partner__link'
                                    href='http://www.poisk-it.ru'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    http://www.poisk-it.ru
                                </a>
                            </div>
                            <div className='company-partner'>
                                <img className='company-partner__logo' alt='' src={infocubes} draggable='false'/>
                                <p className='company-partner__description'>
                                    InfoQubes - лидер в области анализа опыта клиентов для российских компаний.
                                </p>
                                <a
                                    className='company-partner__link'
                                    href='http://www.infoqubes.ru'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    http://www.infoqubes.ru
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='section'>
                    <div className='section__header'>
                        <div className='section__header-line'/>
                        <h2 className='section__header-title'>Новости и события</h2>
                    </div>
                    <div className='section__body'>
                        <div className='company-news'/>
                    </div>
                </div> */}
                <div id='contacts' className='section section_row'>
                    <div className='section__container'>
                        <div className='section__header'>
                            <div className='section__header-line'/>
                            <h2 className='section__header-title'>Контакты</h2>
                        </div>
                        <div className='section__body'>
                            <div className='company-contacts'>
                                <p className='company-contact'>Телефон: +7 (495) 645-44-70</p>
                                <p className='company-contact'>E-mail: info@3itech.ru</p>
                                <p className='company-contact'>Адрес: 129329, Москва, ул. Кольская д.2, кор.6</p>
                            </div>
                            <div className='company-contacts'>
                                <p className='company-contact__header'>Служба сервисной поддержки</p>
                                <p className='company-contact'>Телефон: +7 (495) 645-44-70</p>
                                <p className='company-contact'>E-mail: support@dss-lab.ru</p>
                            </div>
                        </div>
                    </div>
                    <iframe
                        className='map'
                        title='map'
                        // eslint-disable-next-line max-len
                        src='https://yandex.ru/map-widget/v1/?um=constructor%3A91b8ae8a6f5fe4c403fa2b22a5ffe781681ee8e649b977c76055f1cf13e31530&amp;source=constructor'
                        width='624'
                        height='468'
                        frameBorder='0'
                    />
                </div>
            </div>
            { isVisible &&
                <button onClick={scrollToTop} className='button-up'>
                    <img src={buttonUp} alt='' draggable='false'/>
                </button>
            }
        </div>
    )
}
