export default function TextToSpeech () {
    return (
        <div>
            <p className='description__title'>3i Text To Speech</p>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                    Программное обеспечение «3i Text To Speech» предназначено для решения задачи синтеза речи,
                    т.е. для преобразования данных из текстового вида в аудиопоток, содержащий его голосовой аналог.
                    3i TTS API позволяет произвести озвучку любого текста, используя технологию синтеза речи.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Поддерживаемые языки:
                </p>
                <p className='description__text'>Русский язык (Cloud/On-premise)</p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Доступные голоса:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Мужской;
                    </li>
                    <li className='description__list-item'>
                        Женский.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Реализация
                </p>
                <p className='description__text'>
                    ПО состоит из одного модуля 3i-tts-service и реализовано в виде серверного приложения,
                    запускаемого в среде Docker.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Системные требования (минимальные):
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        2 x CPU (2 физических ядра) с частотой 2.4 GHz;
                    </li>
                    <li className='description__list-item'>
                        GPU (графический ускоритель) NVIDIA с поддержкой CUDA и объемом ОЗУ не менее 8 ГБ;
                    </li>
                    <li className='description__list-item'>
                        8 ГБ ОЗУ;
                    </li>
                    <li className='description__list-item'>
                        30 ГБ дискового пространства;
                    </li>
                    <li className='description__list-item'>
                        Linux x64.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>Стоимость ПО определяется на основе технических требований.
                    Для расчета стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_text_to_speech.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
