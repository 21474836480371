import {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'

import menu from '../icons/menu.svg'

export default function Burger () {
    const [burgerMenuOpen, setburgerMenuOpen] = useState(false)

    const ref = useRef()

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (burgerMenuOpen && ref.current && !ref.current.contains(e.target)) setburgerMenuOpen(false)
        }

        document.addEventListener('mousedown', checkIfClickedOutside)

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [burgerMenuOpen])

    return (
        <>
            { burgerMenuOpen ? (
                <div className='navigation-menu' ref={ref}>
                    <Link to='/products'>
                        <button className='navigation-menu_item' onClick={() => setburgerMenuOpen(false)}>
                            Продукты
                        </button>
                    </Link>
                    <a href='/#about'>
                        <button className='navigation-menu_item' onClick={() => setburgerMenuOpen(false)}>
                            О компании
                        </button>
                    </a>
                    <a href='/#partners'>
                        <button className='navigation-menu_item' onClick={() => setburgerMenuOpen(false)}>
                            Партнеры
                        </button>
                    </a>
                    <a href='/#contacts'>
                        <button className='navigation-menu_item' onClick={() => setburgerMenuOpen(false)}>
                            Контакты
                        </button>
                    </a>
                </div>
            )
                : <button
                    className='navigation-menu_icon'
                    onClick={() => setburgerMenuOpen(true)} style={{backgroundImage: `url(${menu})`}}/>
            }
        </>
    )
}
