export default function NLPPlatform () {
    return (
        <div className='description'>
            <p className='description__title'>3i NLP Platform</p>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                    <b>3i NLP Platform</b> предназначен для формирования статистических данных по результатам
                    анализа текстовой информации на естественном языке (ЕЯ).
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Обзор
                </p>
                <p className='description__text'>
                    Программное решение 3i NLP Platform поддерживает распределенную обработку больших объемов данных
                    (Big Data), отказоустойчивое хранение и высокоскоростную обработку данных с помощью средств
                    Hadoop (HDFS, Hbase, SequenceFiles и т.п.). При проведении текстового анализа таких данных
                    выявляется большое количество различной статистической лингвистической информации. Ее учет дает
                    возможность существенно повысить точность и релевантность результатов, например, при поиске и
                    классификации текстов. 3i NLP Platform предоставляет следующие возможности:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        формирование и обработка статистической морфологической информации (кол-во частей речи, их
                        распределение в массиве текстов и др.);
                    </li>
                    <li className='description__list-item'>
                        расчет статистики по выявленным сущностям (персоны, геолокации, организации) и результатам
                        расчетов по определению тональности документов и сущностей;
                    </li>
                    <li className='description__list-item'>
                        выполнение математических операций над статистическими данными (пересечение выявленных групп
                        данных, нахождение экземпляров данных в заданном множестве и др.);
                    </li>
                    <li className='description__list-item'>
                        анализ частотных характеристик морфологической и семантической информации, графическая
                        интерпретация статистических данных.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Реализация
                </p>
                <p className='description__text'>
                    3i NLP Platform функционирует на ОС Linux.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Стоимость
                </p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_NLP_Platform.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
