export default function SearchPlatform () {
    return (
        <div className='description'>
            <h3 className='description__title'>3i Search Platform</h3>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                    3i Search Platform представляет собой гибкую легко масштабируемую платформу корпоративного
                    уровня, реализующую широкий спектр технологий обработки текстовой информации: информационный
                    поиск, классификацию, кластеризацию, аннотирование, выделение сущностей и многих других.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Обзор
                </p>
                <p className='description__text'>
                    3i Search Platform имеет распределенную архитектуру сбора, индексации данных и обработки
                    запросов. Система сбора данных реализована в виде коннекторов к различным источникам данных,
                    например, к файловой системе или Интернет. 3i Search Platform обойдет внутренние и внешние
                    источники данных, объединит их в единое информационное пространство и предоставит доступ
                    через Web-интерфейс. Посредством SOAP-API можно получить доступ к следующим функциям
                    системы:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        поиск по ключевым словам;
                    </li>
                    <li className='description__list-item'>
                        смысловой поиск;
                    </li>
                    <li className='description__list-item'>
                        нечеткий поиск;
                    </li>
                    <li className='description__list-item'>
                        параметрический поиск;
                    </li>
                    <li className='description__list-item'>
                        поиск похожих документов;
                    </li>
                    <li className='description__list-item'>
                        поиск по различным типам полей;
                    </li>
                    <li className='description__list-item'>
                        поиск с использованием усечения (wildcard);
                    </li>
                    <li className='description__list-item'>
                        поиск с учетом синонимов и пользовательских словарей;
                    </li>
                    <li className='description__list-item'>
                        кросс-языковый поиск;
                    </li>
                    <li className='description__list-item'>
                        автоматическое дополнение запросов при поиске;
                    </li>
                    <li className='description__list-item'>
                        проверка правописания запросов;
                    </li>
                    <li className='description__list-item'>
                        анализ статистики по запросам (анализ интересов пользователей);
                    </li>
                    <li className='description__list-item'>
                        классификация документов;
                    </li>
                    <li className='description__list-item'>
                        ведение различных типов классификаторов для совместной работы (пользовательские, групповые,
                        общие);
                    </li>
                    <li className='description__list-item'>
                        кластеризация документов;
                    </li>
                    <li className='description__list-item'>
                        построение карты связей для кластеров;
                    </li>
                    <li className='description__list-item'>
                        динамическая кластеризация результатов поиска;
                    </li>
                    <li className='description__list-item'>
                        ведение пользовательских агентов для мониторинга данных и уведомлений;
                    </li>
                    <li className='description__list-item'>
                        смысловое аннотирование.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Технические характеристики
                </p>
                <p className='description__text'>
                    Коннекторы: файловая система, Интернет, POP3/IMAP, Fetch API.
                </p>
                <p className='description__text'>
                    Типы файлов: более 200 различных форматов, среди них XML, HTML, PDF, файлы Microsoft
                    Office и многие другие. Для XML и HTML имеются гибкие настройки.
                </p>
                <p className='description__text'>
                    3i Search Platform поддерживает лингвистическую обработку документов на следующих языках:
                    английский, арабский, иврит, итальянский, испанский, китайский (трад.), китайский (упр.),
                    корейский, немецкий, польский, португальский, русский, турецкий, французский, японский. Также
                    для указанного списка доступно автоматическое определение языка. Список поддерживаемых языков
                    пополняется.
                </p>
                <p className='description__text'>
                    Скоростные характеристики на современных процессорах семейства Intel Xeon:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        индексация на одном ядре со скоростью более 4 MB/s (32 Mbps);
                    </li>
                    <li className='description__list-item'>
                        выполнение более 50 простых запросов в секунду.
                    </li>
                </ul>
                <p className='description__text'>
                    3i Search Platform поддерживает линейную масштабируемость по количеству обрабатываемых запросов
                    и проиндексированных данных. Рекомендуемое количество документов на один узел индексации - до 5
                    млн.
                </p>
                <p className='description__text'>
                    3i Search Platform разработана на основе самых передовых достижений в области обработки текстов
                    на естественном языке (Natural Language Processing) и по своим характеристикам не уступает
                    мировым лидерам отрасли корпоративных поисковых систем.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Стоимость
                </p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Документация
                </p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_Search_Platform_Руководство_по_установке.pdf'
                download
            >
                Руководство по установке
            </a>
            <a
                className='description__download-link'
                href='/files/3i_Search_Platform_Руководство_программиста.pdf'
                download
            >
                Руководство программиста
            </a>

        </div>
    )
}
