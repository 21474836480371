export default function LanguageId () {
    return (
        <div>
            <p className='description__title'>3i Language ID</p>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                    3i Language ID &ndash; Специальное программное обеспечение, предназначенное для автоматической
                    идентификации языка по голосу в аудио-потоке. Технология может эффективно работать как с
                    телерадиовещательным, так и с телефонным каналом входящих данных.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Поддерживаемые языки:
                </p>
                <p className='description__text'>Телерадиовещание (Broadcast):</p>
                <ul className='description__list'>
                    <li className='description__list-item'>Русский;</li>
                    <li className='description__list-item'>Английский;</li>
                    <li className='description__list-item'>Китайский;</li>
                    <li className='description__list-item'>Немецкий;</li>
                    <li className='description__list-item'>Испанский.</li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__text'>Телефония (Phone):</p>
                <ul className='description__list'>
                    <li className='description__list-item'>Русский;</li>
                    <li className='description__list-item'>Английский.</li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Технология
                </p>
                <p className='description__text'>
                    Используемая в 3i Language ID технология основана на вычислении наиболее вероятной
                    последовательности звуков и слов, и определении вероятности порождения моделью целевого языка
                    этой наиболее вероятной последовательности. Данный процесс повторяется для каждого целевого
                    языка. Таким образом, учитывается акустическая и лингвистическая статистика распределения
                    единиц языка (фонем\слогов\слов) на основе Скрытой Марковской модели для моделирования
                    последовательностей лингвистических единиц речи и глубокой нейронной сети для моделирования
                    акустики речи.
                </p>
                <p className='description__text'>
                    Предполагается, что речь на нецелевом языке будет иметь меньшую вероятность порождения моделью
                    целевого языка, чем речь на целевом языке, поскольку фонетический состав, акустические
                    особенности и
                    распределение слов в нецелевом языке значительно отличается от целевого.
                </p>
                <p className='description__text'>
                    Каждый язык из целевого множества языков должен иметь модель языка, которая поставляется
                    совместно с модулем. Кроме того, каждый тип канала (броадкаст, телефония) для каждого языка
                    также имеет модель языка.
                </p>
                <p className='description__text'>
                    Обрабатываемую запись рекомендуется очистить от пауз, неречевых звуков, музыки, для этого
                    рекомендуется воспользоваться модулем 3i Speech Detector SDK.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Модификация
                </p>
                <p className='description__text'>
                    Существует две модификации ПО: для определения языка в телефонных записях и для
                    широковещательного аудио-потока.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Реализация
                </p>
                <p className='description__text'>
                    Продукт реализован в виде динамической библиотеки (so), написанной на языке С++. 3i Language ID
                    используется в качестве программного обеспечения, предоставляя разработчику соответствующий
                    функционал
                    API.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Преимущества
                </p>
                <p className='description__text'>
                    К основным преимуществам приложения, предоставляющего shared object API в качестве инструмента
                    доступа к функциональным возможностям, можно отнести:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        надёжность (за счет отсутствия необходимости взаимодействия с удаленным модулем);
                    </li>
                    <li className='description__list-item'>
                        производительность (за счет использования кэша и
                        подгрузки кода и данных в пространство адресов приложения);
                    </li>
                    <li className='description__list-item'>
                        прозрачность системы взаимодействия;
                    </li>
                    <li className='description__list-item'>
                        легкость внесения изменений;
                    </li>
                    <li className='description__list-item'>
                        масштабируемость.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Системные требования
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        CPU Intel Core i7 &ndash; 5820K 3.3 ГГц (6 физических вычислительных ядер);
                    </li>
                    <li className='description__list-item'>
                        ОЗУ 16 ГБ;
                    </li>
                    <li className='description__list-item'>
                        100 Гб свободного места на жёстком диске;
                    </li>
                    <li className='description__list-item'>
                        Linux x64.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>Стоимость ПО определяется на основе технических требований.
                    Для расчета стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_Lang_ID.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
