export default function ToneDetector() {
    return (
        <div className='description'>
            <p className='description__title'>
                3i Tone Detector SDK
            </p>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                    Библиотека 3i Tone Detector предназначена для обнаружения в звуковом потоке следующих типов
                    сегментов:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        одно- и двухтональных гудков;
                    </li>
                    <li className='description__list-item'>
                        сигналов тонального набора (DTMF).
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технология</p>
                <p className='description__text'>
                    Детектирование одно- и двухтональных гудков выполняется на основе анализа информации о
                    стационарности доминирующих частот в спектре сигнала.
                </p>
                <p className='description__text'>
                    Детектирование и определение типа сигналов тонального набора DTMF выполняется на базе алгоритма
                    Герцеля (вычисляется в 500 раз быстрее, чем быстрое Фурье-преобразование).
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технические характеристики</p>
                <p className='description__text'>
                    Тип обрабатываемого сигнала
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        WAV-файлы, буфер отсчётов;
                    </li>
                    <li className='description__list-item'>
                        частота дискретизации 8 кГц;
                    </li>
                    <li className='description__list-item'>
                        разрядность квантования 8 или 16-бит;
                    </li>
                    <li className='description__list-item'>
                        тип кодирования: A-закон, m-закон или PCM.
                    </li>
                </ul>
                <p className='description__text'>
                    Требования к качеству речевого сигнала - ОСШ не менее 10 дБ, допускается присутствие посторонних
                    неречевых звуков.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Реализация</p>
                <p className='description__text'>
                    SDK реализован в виде DLL-библиотеки, написанной на языке С++, позволяющей потокобезопасное
                    встраивание функций автоматического определения пола в произвольные Windows-приложения.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>Стоимость ПО определяется на основе технических требований.
                    Для расчета стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_Tone_Detector_SDK.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
