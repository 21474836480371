import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'

import App from './components/App'
import reportWebVitals from './reportWebVitals'

import 'reset-css'
import './index.scss'
import './fonts/SFProDisplay-Regular.woff'

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>,
    document.querySelector('#root')
)

reportWebVitals()
