export default function SpeechDetector () {
    return (
        <div className='description'>
            <p className='description__title'>3i Speech Detector SDK</p>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Назначение
                </p>
                <p className='description__text'>
                    Библиотека 3i Speech Detector предназначена для обнаружения в звуковом потоке следующих типов
                    сегментов:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>участков тишины и фона;</li>
                    <li className='description__list-item'>одно- и двухтональных гудков;</li>
                    <li className='description__list-item'>речевых сигналов;</li>
                    <li className='description__list-item'>сигналов тонального набора (DTMF).</li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технология</p>
                <p className='description__text'>
                    В библиотеке реализовано два подхода к сегментации звукового потока.
                </p>
                <p className='description__text'>
                    Первый подход является аналогом VAD – Voice Activity Detection и работает на основе информации
                    об уровне энергии в сигнале.
                </p>
                <p className='description__text'>
                    Второй подход основан на обнаружении в звуковом потоке признаков основного тона (PTD – Pitch
                    Tone Detection). Присутствие в сигнале признаков основного тона, как правило, сигнализирует о
                    наличии речи. Определение основного тона осуществляется при помощи комбинации следующих методов:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        метод частотной селекции;
                    </li>
                    <li className='description__list-item'>
                        метод на основе кепстральных коэффициентов;
                    </li>
                    <li className='description__list-item'>
                        метод на основе классической автокорреляционной функции (ACF – autocorrelation function);
                    </li>
                    <li className='description__list-item'>
                        модифицированный автокорреляционный метод (AMDF - average magnitude difference function
                        based method).
                    </li>
                </ul>
                <p className='description__text'>
                    Комбинация четырёх указанных методов обеспечивает высокую надёжность обнаружения речевой
                    составляющей в звуковом потоке даже в сигналах с высоким уровнем помех.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Модификации
                </p>
                <p className='description__text'>
                    Существует две модификации продукта, каждая из которых реализует один из указанных выше подходов
                    к сегментации звукового потока, и, соответственно, отличающихся возможностями обработки
                    зашумленных речевых сигналов. Модификация «b» (3i-SDt(b)-SDK) эффективно обрабатывает сигналы с
                    отношением сигнал/шум (ОСШ) не менее 10 дБ с отсутствием посторонних звуков (кроме гудков).
                    Модификация «p» (3i- SDt(p)-SDK) предназначена для обработки сигналов с ОСШ не менее 7 дБ, при
                    этом допускается присутствие посторонних неречевых звуков.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Технические характеристики</p>
                <p className='description__text'>Тип обрабатываемого сигнала</p>

                <ul className='description__list'>
                    <li className='description__list-item'>WAV-файлы, буфер отсчётов;</li>
                    <li className='description__list-item'>частота дискретизации 8 кГц;</li>
                    <li className='description__list-item'>разрядность квантования 8 или 16-бит;</li>
                    <li className='description__list-item'>тип кодирования: A-закон, m-закон или PCM.</li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Скорость обработки</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        модификация «b» - в 150-200 раз быстрее времени звучания на одном ядре процессора;
                    </li>
                    <li className='description__list-item'>
                        модификация «p» - в 15-20 раз быстрее времени звучания на одном ядре процессора.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>
                    Требования к качеству речевого сигнала
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        модификация «b» - ОСШ не менее 10 дБ, отсутствие посторонних неречевых звуков (кроме
                        гудков);
                    </li>
                    <li className='description__list-item'>
                        модификация «p» - ОСШ не менее 7 дБ, допускается присутствие посторонних неречевых звуков.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Реализация</p>
                <p className='description__text'>
                    Библиотека реализована в виде DLL-библиотеки, написанной на языке С++, позволяющей
                    потокобезопасное встраивание функций автоматического определения пола в произвольные
                    Windows-приложения.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>Стоимость ПО определяется на основе технических требований.
                    Для расчета стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_Speech_Detector_SDK.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
