export default function SearchPlatform3 () {
    return (
        <div className='description'>
            <p className='description__title'>3i Search Platform 3.x</p>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                    3i Search Platform 3.x представляет собой гибкую легко горизонтально и вертикально
                    масштабируемую платформу для Big Data. Она применяется в информационно-аналитических системах
                    крупных предприятий и центрах обработки данных (ЦОД). 3i Search Platform 3.x реализует широкий
                    спектр технологий обработки текстовой информации: информационный поиск, классификацию,
                    кластеризацию, аннотирование, выделение сущностей и многих других.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Обзор</p>
                <p className='description__text'>
                    3i Search Platform 3.x представляет собой развитие платформы 3i Search Platform (2.х). Поисковые
                    возможности 3i Search Platform 3.x базируются на технологиях Elasticsearch – распределенного
                    поискового сервера с открытым кодом для поиска и анализа данных в режиме реального времени.
                    Компания “ДСС Лаб” расширяет его функционал за счет включения в состав ряда плагинов собственной
                    разработки. API 3i Search Platform 3.x сохраняет возможности API Elasticsearch, добавляя новые
                    функции. 3i Search Platform 3.x имеет распределенную архитектуру индексации данных, обработки
                    запросов и может использоваться как документно-ориентированная NoSQL база. 3i Search Platform
                    3.x предоставляет пользователю развитой функционал обработки текстовой информации:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Расширенный поиск. Технология расширенного поиска базируется на мощном и гибком языке
                        запросов, позволяющем выполнять профессиональное исследование информации. Например, помимо
                        традиционных логических операторов возможен поиск с учетом морфологии языка (усечение
                        грамматических форм и т.п.), по конкретным типам полей и др.
                    </li>
                    <li className='description__list-item'>
                        Автодополнение и проверка правописания. Обеспечивается возможность при формировании запроса
                        автодополнять отдельные слова и/или словосочетания, а также осуществлять проверку
                        правописания. В основе данной технологии лежит качественная морфология языков, позволяющая
                        на основе имеющихся в системе словарей исправлять грамматические ошибки и опечатки, понимать
                        транслитерированные слова.
                    </li>
                    <li className='description__list-item'>
                        Кроссязыковый поиск. Обеспечивается возможность поиска требуемой информации на нескольких
                        языках одновременно.
                    </li>
                    <li className='description__list-item'>
                        Поиск с учетом синонимов. Обеспечивается возможность выполнения поиска не только по
                        непосредственно введенному пользователем слову или сочетанию, но и параллельно по их
                        синонимам.
                    </li>
                    <li className='description__list-item'>
                        Поиск похожих документов. Технология основана на выявлении ограниченного числа наиболее
                        значимых слов в исходном документе и поиску по ним нечетких дубликатов.
                    </li>
                    <li className='description__list-item'>
                        Нечеткий поиск. Обеспечивается выполнение поиска в текстовых данных, содержащих
                        орфографические ошибки или опечатки. На практике такой подход позволяет, например, выявлять
                        ситуации преднамеренной замены символов, например, буквы «О» на цифру «0» с целью усложнения
                        поиска информации.
                    </li>
                    <li className='description__list-item'>
                        Извлечение сущностей. Обеспечивается возможность распознавания и извлечения из текста
                        определенной значимой информации – сущностей (люди, места, организации, номера телефонов,
                        продукты или услуги и т.п.). В основе используемой технологии лежит частичный синтаксический
                        анализ и машинное обучение.
                    </li>
                    <li className='description__list-item'>
                        Кластеризация информации. Обеспечивается возможность автоматической группировки схожих по
                        смыслу документов. По своим характеристикам 3i Search Platform 3.x является лидером для
                        рынка Big Data. Примером использования 3i Search Platform 3.x является сайт анализа
                        новостей, поступающих от средств массовой
                        информации<a href='http://www.3inews.ru'> www.3inews.ru</a>.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технические характеристики</p>
                <p className='description__text'>
                    3i Search Platform 3.х используется в качестве отдельного модуля, встраиваемого в другое
                    программное обеспечение, предоставляя разработчику соответствующий функционал API. API 3i Search
                    Platform 3.х относится к классу REST (сокр. англ. Representational State Transfer, передача
                    репрезентативного состояния). 3i Search Platform 3.x поддерживает лингвистическую обработку
                    документов на следующих языках: английский, арабский, иврит, итальянский, испанский, китайский
                    (трад.), китайский (упр.), корейский, немецкий, польский, португальский, русский, турецкий,
                    французский, японский. Также для указанного списка доступно автоматическое определение языка.
                    Список поддерживаемых языков пополняется. Программное обеспечение функционирует под ОС Linux. 3i
                    Search Platform 3.x разработана на основе самых передовых достижений в области обработки текстов
                    на естественном языке (Natural Language Processing).
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_SP_3.x.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
