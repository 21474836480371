export default function SpeakerIdSDK () {
    return (
        <div className='description'>
            <p className='description__title'>3i Speaker ID SDK</p>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                    Библиотека 3i Speaker ID предназначена для разработки программного обеспечения, в котором
                    необходимо решать задачу автоматической текстонезависимой идентификации дикторов по речевым
                    сообщениям, передающимся как по телевизионным каналам, так и по каналам телефонной связи.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технология</p>
                <p className='description__text'>
                    Технология идентификации дикторов по голосу основана на последних достижениях в области анализа
                    речи и принятия решений. В предлагаемом продукте реализован целый ряд самостоятельных методов
                    голосовой идентификации. Среди них известные и уже ставшие традиционными подходы, основанные на
                    Гауссовых смесях (GMM – Gaussian Mixture Model), собственных векторах (i-vectors) и
                    супервекторах. Кроме традиционных методов, в библиотеке применены оригинальные решения как в
                    области первичной обработки речевых сигналов и кодирования акустических признаков речи, так и в
                    области построения голосовых моделей и методов принятия решений. В отличие от существующих
                    подходов, например, в предлагаемой технологии оценка достоверности идентификации основана на
                    результатах взвешенного голосования ряда отдельных независимых решателей. Благодаря этому
                    достигнута высокая устойчивость правильной идентификации в различных каналах связи.
                </p>
                <p className='description__text'>
                    Специальный модуль, выделяющий речевую составляющую из звукового потока на основе определителя
                    основного тона, обеспечивает устойчивость в условиях присутствия в звуковом потоке посторонних -
                    не речевых - вставок.
                </p>
            </div>

            <div className='description__section'>
                <p className='description__sub-title'>Модификации</p>
                <p className='description__text'>
                    Существует две модификации продукта, отличающиеся возможностями обработки зашумленных речевых
                    сигналов. Модификация «b» (3i-SID (b)-SDK) эффективно обрабатывает сигналы с отношением
                    сигнал/шум (ОСШ) не менее 10 дБ с отсутствием посторонних звуков (кроме гудков). Модификация «p»
                    (3i-SID (p)-SDK) предназначена для обработки сигналов с ОСШ не менее 7 дБ, при этом допускается
                    присутствие посторонних неречевых звуков.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технические характеристики</p>
                <p className='description__text'>Равновероятная ошибка (EER): 7,37%</p>
                <p className='description__text'>
                    Тестирование проводилось на единой базе, состоящей более чем из трех тысяч фонограмм различной
                    продолжительности, записанных в разных каналах. Минимальное количество речи в фонограмме – 10
                    сек. Максимальное – 10 мин. Среднее – 90 сек. Количество целевых дикторов: 100. При
                    идентификации предполагается, что фонограмма содержит речь только одного диктора.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Скорость обработки</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        модификация «b» - в 150-200 раз быстрее времени звучания на одном ядре процессора;
                    </li>
                    <li className='description__list-item'>
                        модификация «p» - в 15-20 раз быстрее времени звучания на одном ядре процессора.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Тип обрабатываемого сигнала</p>
                <ul className='description__list'>
                    <li className='description__list-item'>WAV-файлы, буфер отсчётов;</li>
                    <li className='description__list-item'>частота дискретизации - 8 кГц;</li>
                    <li className='description__list-item'>разрядность квантования - 8 или 16 бит;</li>
                    <li className='description__list-item'>тип кодирования: A-закон, m-закон или PCM.</li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Требования к качеству речевого сигнала</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        модификация «b» - ОСШ не менее 10 дБ, отсутствие посторонних неречевых звуков (кроме
                        гудков);
                    </li>
                    <li className='description__list-item'>
                        модификация «p» - ОСШ не менее 7 дБ, допускается присутствие посторонних неречевых звуков.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Реализация</p>
                <p className='description__text'>
                    SDK реализован в виде DLL-библиотек, написанных на языке С++, позволяющих потокобезопасное
                    встраивание функций идентификации диктора в произвольные Windows-приложения.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <p>
                <a
                    className='description__download-link'
                    href='/files/3i_Speaker_ID.pdf'
                    download
                >
                    Скачать документацию
                </a>
            </p>
        </div>
    )
}
