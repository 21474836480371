import ProductsList from './ProductsList'
import TouchPointAnalytics from './ProductItems/TouchPointAnalytics'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import DataPlexus from './ProductItems/DataPlexus'
import DssLabStrice from './ProductItems/DssLabStrice'
import SearchPlatform from './ProductItems/SearchPlatform'
import SearchPlatform3 from './ProductItems/SearchPlatform-3'
import BigDataAnalyticsProcessingPlatform from './ProductItems/BigDataAnalyticsProcessingPlatform'
import NLPPlatform from './ProductItems/NLPPlatform'
import Crawler from './ProductItems/Crawler'
// import ADPI from './ProductItems/ADPI'
import SpeakerIdSDK from './ProductItems/SpeakerIdSDK'
import SpeakerIdSDK3 from './ProductItems/SpeakerIdSDK3'
import GenderId from './ProductItems/GenderId'
import SpeechDetector from './ProductItems/SpeechDetector'
import SpeechTranscriptor from './ProductItems/SpeechTranscriptor'
import SpeechDiarization from './ProductItems/SpeechDiarization'
import LanguageId from './ProductItems/LanguageId'
import TextToSpeech from './ProductItems/TextToSpeech'
// import NewSens from './ProductItems/NewSens'
// import VoiceCaptureBox from './ProductItems/VoiceCaptureBox'
import React, {useEffect, useState} from 'react'
import btnUp from '../../icons/arrow-up-circle.png'
import GoToTop from './ProductItems/GoToTop'
import ToneDetector from './ProductItems/ToneDetector'
import SpeechService from './ProductItems/SpeechService'

export default function Products() {
    const location = useLocation()
    const [isMobile, setMobile] = useState(window.innerWidth < 1024)
    const [isVisible, setIsVisible] = useState(false)

    const toggleVisibility = () => {
        if (window.scrollY > 500) setIsVisible(true)
        else setIsVisible(false)
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility)
        return () => window.removeEventListener('scroll', toggleVisibility)
    }, [])

    const goToTop = () => window.scrollTo(0, 0)
    const updateMedia = () => setMobile(window.innerWidth < 1024)
    useEffect(() => {
        window.addEventListener('resize', updateMedia)
        return () => window.removeEventListener('resize', updateMedia)
    })

    return (
        <div className='products'>
            {((location.pathname === '/products') || !isMobile) && <p className='products__title'>Продукты</p>}
            <div className='products__page'>
                {((location.pathname === '/products') || !isMobile) && <ProductsList/>}
                <Routes>
                    {!isMobile && <Route path='' element={<Navigate to='touchpointanalytics'/>}/>}
                    <Route path='/touchpointanalytics' element={<TouchPointAnalytics/>}/>
                    <Route path='/data-plexus' element={<DataPlexus/>}/>
                    <Route path='/dsslabstrice' element={<DssLabStrice/>}/>
                    <Route path='/search-platform' element={<SearchPlatform/>}/>
                    <Route path='/search-platform-3' element={<SearchPlatform3/>}/>
                    <Route path='/dpp-platform' element={<BigDataAnalyticsProcessingPlatform/>}/>
                    <Route path='/nlp-platform' element={<NLPPlatform/>}/>
                    <Route path='/crawler' element={<Crawler/>}/>
                    {/*<Route path='/3i-adpi' element={<ADPI/>}/>*/}
                    <Route path='/speaker-id' element={<SpeakerIdSDK/>}/>
                    <Route path='/speaker-id-3' element={<SpeakerIdSDK3/>}/>
                    <Route path='/gender-id' element={<GenderId/>}/>
                    <Route path='/speech-detector' element={<SpeechDetector/>}/>
                    <Route path='/speech-tone-detector' element={<ToneDetector/>}/>
                    <Route path='/speech-transcriptor' element={<SpeechTranscriptor/>}/>
                    <Route path='/speech-diar' element={<SpeechDiarization/>}/>
                    <Route path='/lang-id' element={<LanguageId/>}/>
                    <Route path='/text-to-speech' element={<TextToSpeech/>}/>
                    <Route path='/speech-service' element={<SpeechService/>}/>
                    {/*<Route path='/newsens' element={<NewSens/>}/>*/}
                    {/*<Route path='/voice-capture-box' element={<VoiceCaptureBox/>}/>*/}
                </Routes>
                {isMobile && isVisible &&
                    <button onClick={goToTop} className='description__btn-up'>
                        <img src={btnUp} alt='btnUp'/>
                    </button>
                }
            </div>
            <GoToTop/>
        </div>
    )
}
