export default function BigDataAnalyticsProcessingPlatform () {
    return (
        <div className='description'>
            <p className='description__title'>3i Big Data Analytics Processing Platform</p>
            <div className='description__section'>
                <p className='description__sub-title'>Назначение</p>
                <p className='description__text'>
                    <b>3i Big Data Analytics Processing Platform</b> – это платформенное решение, предоставляющее
                    пользователю развитый функционал анализа контента соцсетей через интерфейс API.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Обзор</p>
                <p className='description__text'>
                    CПО 3i Big Data Analytics Processing Platform обеспечивает автоматическое получение сведений о
                    текущей активности пользователей (посты, репосты, лайки и комментарии), а также структуре их
                    взаимодействий на уровне отдельных пользователей и групп. К числу базовых количественных
                    аналитических показателей относятся:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Коэффициент популярности. Популярность вершины/пользователя определяется количеством
                        вершин/пользователей, на которые она может оказывать прямое воздействие.
                    </li>
                    <li className='description__list-item'>
                        Коэффициент хабовости. Параметр, так называемой, «хабовости» вершины/пользователя
                        определяется количеством вершин/пользователей, на которые она ссылается. Иными словами,
                        значение хабовости конкретной вершины описывает количество вершин, от которых она получает
                        информацию.
                    </li>
                    <li className='description__list-item'>
                        Коэффициент близости. Близость вершины/пользователя – показатель, характеризующий, насколько
                        близко находится данная вершина в цепочках связей ко всем остальным вершинам/пользователям
                        социальной сети.
                    </li>
                    <li className='description__list-item'>
                        Коэффициент посредничества. Посредничество вершины/пользователя – это параметр, указывающий
                        вероятность того, что выбранная вершина/пользователь будет вовлечена во все коммуникации,
                        проводимые по кратчайшим путям между двумя другими вершинами/пользователями. Иными словами,
                        параметр посредничества помогает оценить возможности данной вершины в части управления
                        коммуникациями между другими вершинами/пользователями.
                    </li>
                    <li className='description__list-item'>
                        Коэффициент авторитетности. PageRank – популярная в сетевом анализе мера центральности
                        вершин/пользователей по собственному вектору. Алгоритм применяется к коллекции
                        вершин/пользователей, связанных гиперссылками, и каждой вершине назначается некоторое
                        численное значение, измеряющее его относительную важность среди остальных
                        вершин/пользователей.
                    </li>
                    <li className='description__list-item'>
                        Коэффициент прямого влияния. Прямое влияние определяется как соотношение активности
                        выбранной вершины/пользователя по отношению к соседним вершинам/пользователям.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технические характеристики</p>
                <p className='description__text'>
                    Расчетные параметры активности пользователей соцсети используются для решения различных
                    аналитических задач. Так, с их помощью можно произвести выявление вершин/пользователей, играющих
                    в течение времени устойчивые роли, например, адвоката бренда или тролля, проследить динамику
                    изменений в составе интересующих групп, а также исследовать маршруты распространения информации
                    по сети и обеспечивать ранее предупреждение о развивающейся информационной кампании в соцсети.
                </p>
                <p className='description__text'>
                    Фактически программный инструментарий 3i Big Data Analytics Processing Platform предоставляет
                    аналитикам все необходимые сведения о поведении пользователей соцсети в автоматическом режиме и
                    дает им возможность сосредоточиться на интеллектуальной задаче выявления разного рода активности
                    в соцсети и оценке сетевых угроз.
                </p>
                <p className='description__text'>
                    Платформенное решение 3i Big Data Analytics Processing Platform обеспечивает анализ данных сетей
                    ВКонтакте, Facebook, Twitter, «Одноклассники».
                </p>
                <p className='description__text'>
                    Решение 3i Big Data Analytics Processing Platform, предназначенное для высокопроизводительной
                    аналитической обработки данных соцсетей - это отечественное ПО, использующее для целей
                    распределенной обработки данных СПО-технологии Apache Hadoop и Spark.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Реализация</p>
                <p className='description__text'>
                    3i Big Data Analytics Processing Platform функционирует на ОС Linux.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_Big_Data%20Analytics_Processing_Platform.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
