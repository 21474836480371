export default function DataPlexus () {
    return (
        <div className='description'>
            <p className='description__title'>3i DataPlexus</p>
            <p className='description__more-info'>
                БОЛЕЕ ПОДРОБНАЯ ИНФОРМАЦИЯ О ПРОДУКТЕ ДОСТУПНА ПО ССЫЛКЕ:
            </p>
            <a
                className='description__more-info-link'
                href='https://data-plexus.ru/'
            >
                https://data-plexus.ru/
            </a>
            <p className='description__text'>
                <b>3i DataPlexus</b> - облачная платформа для мониторинга, извлечения и анализа
                неструктурированной информации из неограниченного числа разнородных источников данных.
            </p>
            <div className='description__section'>
                <p className='description__sub-title'>Сферы применения:</p>
                <div className='data-plexus-table'>
                    <div className='data-plexus-table__title description__sub-title_uppercase'>
                        Маркетинг
                    </div>
                    <div className='data-plexus-table__title description__sub-title_uppercase'>
                        Продажи
                    </div>
                    <div className='data-plexus-table__item'>
                        Анализ потребителей и конкурентов
                    </div>
                    <div className='data-plexus-table__item'>
                        Поиск и выявление потенциальных заказчиков в соцсетях
                    </div>
                    <div className='data-plexus-table__item'>
                        Выявление рыночных трендов
                    </div>
                    <div className='data-plexus-table__item'>
                        Мониторинг событий вокруг заказчиков
                    </div>
                    <div className='data-plexus-table__item'>
                        Оценка эффективности маркетинговых кампаний
                    </div>
                    <div className='data-plexus-table__item'>
                        Отслеживание конкурсов
                    </div>
                </div>
                <div className='data-plexus-table'>
                    <div className='data-plexus-table__title description__sub-title_uppercase'>
                        Pr
                    </div>
                    <div className='data-plexus-table__title description__sub-title_uppercase'>
                        Клиентский сервис
                    </div>
                    <div className='data-plexus-table__item'>
                        Управление репутацией компании или персоны
                    </div>
                    <div className='data-plexus-table__item'>
                        Оценка удовлетворенности клиентов
                    </div>
                    <div className='data-plexus-table__item'>
                        Отслеживание публикаций и упоминаний
                    </div>
                    <div className='data-plexus-table__item'>
                        Отработка негативных отзывов
                    </div>
                    <div className='data-plexus-table__item'>
                        Оценка эффективности маркетинговых кампаний
                    </div>
                    <div className='data-plexus-table__item'>
                        Отслеживание конкурсов
                    </div>
                </div>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Интеллектуальный анализ</p>
                <p className='description__text'>Собственный механизм текстовой аналитки на базе нейросетевых
                    технологий и машинного обучения автоматически производит анализ входящих информационных потоков,
                    извлекает факты и строит связи между событиями.
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Автоматическая кластеризация документов;
                    </li>
                    <li className='description__list-item'>
                        Автоматическое определение тональности упоминаний в разрезе персоны, компании, бренда или
                        события;
                    </li>
                    <li className='description__list-item'>
                        Автоматическое извлечение из сообщений упоминания персон, брендов, организаций,
                        географических наименований;
                    </li>
                    <li className='description__list-item'>
                        Полнотекстовый поиск по собранным сообщениям с учетом морфологии и возможностью
                        использования логических операторов;
                    </li>
                    <li className='description__list-item'>
                        Гибкая фильтрация по метаданным сообщений &ndash; дате и времени публикации, источнику,
                        автору, языку и пр.;
                    </li>
                    <li className='description__list-item'>
                        Выявление наиболее обсуждаемых тем и информационных поводов;
                    </li>
                    <li className='description__list-item'>
                        Выявление связей между информационными поводами, персонами, организациями;
                    </li>
                    <li className='description__list-item'>
                        Автоматическое определение языка публикации.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Бизнес-Досье</p>
                <p className='description__text'>
                    Досье ключевой элемент системы. Разрозненная информация вокруг объекта интереса агрегируется в
                    едином представлении.
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Гибко настраиваемые информационные ленты вокруг объекта наблюдения: ленты новостей,
                        упоминания, участие в закупках и конкурсах, кадровые изменения и прочее;
                    </li>
                    <li className='description__list-item'>
                        Автоматическое заполнение карточки компании сведениями из ФНС;
                    </li>
                    <li className='description__list-item'>
                        Динамическое обновление информации;
                    </li>
                    <li className='description__list-item'>
                        Оповещения по заданным событиям.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Визуализация и отчеты</p>
                <p className='description__text'>
                    Гибко настраиваемые информационные панели и широкий аналитический инструментарий для
                    всестороннего анализа отобранных данных
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Построение рейтингов на основе частотности публикаций;
                    </li>
                    <li className='description__list-item'>
                        Гибкая фильтрация сообщений по любому набору параметров;
                    </li>
                    <li className='description__list-item'>
                        Ранжирование источников по популярности и цитируемости;
                    </li>
                    <li className='description__list-item'>
                        Полнотекстовый поиск с учетом морфологии и омонимии;
                    </li>
                    <li className='description__list-item'>
                        Сегментация данных по широком набору фильтров;
                    </li>
                    <li className='description__list-item'>
                        Настраиваемые отчеты с графиками и табличными данными;
                    </li>
                    <li className='description__list-item'>
                        Сравнение объектов мониторинга.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Преимущества системы:</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        Поддержка персональных типы объектов для мониторинга;
                    </li>
                    <li className='description__list-item'>
                        Настройка интеллектуального анализа текстов на уровне пользователя;
                    </li>
                    <li className='description__list-item'>
                        Собственная база знаний для поиска и анализа информации;
                    </li>
                    <li className='description__list-item'>
                        Быстрое подключение новых источников данных;
                    </li>
                    <li className='description__list-item'>
                        Поддержка групповой работы с разделением прав доступа;
                    </li>
                    <li className='description__list-item'>
                        Гибко настраиваемые аналитические дашборды;
                    </li>
                    <li className='description__list-item'>
                        Автоматическая генерация отчетов;
                    </li>
                    <li className='description__list-item'>
                        Оповещения на e-mail по триггерам и событиям;
                    </li>
                    <li className='description__list-item'>
                        Экспорт данных в Excel;
                    </li>
                    <li className='description__list-item'>
                        API для интеграции со сторонними системами.
                    </li>
                </ul>
            </div>
        </div>
    )
}
