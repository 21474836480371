export default function SpeechTranscriptor () {
    return (
        <div className='description'>
            <p className='description__title'>
                3i Speech Transcriptor
            </p>
            <div className='description__section'>
                <p className='description__sub-title'>
                    Назначение
                </p>
                <p className='description__text'>
                    Сециальное программное обеспечение 3i Speech Transcriptor предназначено для решения задачи
                    преобразования речи в текст, передающейся по медиаканалам (ТВ, радио) и по каналам телефонной
                    связи (традиционной, сотовой, IP-телефонии).
                </p>
                <p className='description__text'>
                    3i Speech Transcriptor предоставляет разработчику API.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Технология</p>
                <p className='description__text'>
                    Возможности распознавания речи 3i Speech Transcriptor базируются на технологиях DNN и
                    WFST &ndash; глубоких нейронных сетей (deep neural networks) и взвешенных конечных автоматов
                    (weighted finite state transducer).
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>Основные характеристики</p>
                <p className='description__text'>
                    Использованные компанией технологии распознавания речи обеспечивают:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        высокую скорость обработки речевого сигнала, за счет распараллеливания вычислений;
                    </li>
                    <li className='description__list-item'>
                        современное качество распознавания речи;
                    </li>
                    <li className='description__list-item'>
                        возможность гибкой настройки модуля распознавания речи на тип канала (телевидение и радио),
                        телефония (традиционная, сотовая, IP-телефония) и\или язык за счет использования обучаемых
                        моделей, распространяемых независимо;
                    </li>
                    <li className='description__list-item'>
                        дикторонезависимое распознавание слитной речи, в том числе при наличии акцента, внешних
                        шумов, неречевых звуков, музыки;
                    </li>
                    <li className='description__list-item'>
                        распознавание файлов или потоков речи неограниченной длины за счет деления записей по паузам
                        внутри речи и распознавания получившихся кусочков в отдельных потоках ЦП;
                    </li>
                    <li className='description__list-item'>
                        большой словарь распознаваемых слов, включающий сотни тысяч слов, чего практически
                        достаточно для распознавания любого текста общей лексики.
                    </li>
                </ul>
                <p className='description__text'>
                    Возможна адаптация существующих языковых моделей и разработка новых под требования
                    Заказчика.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__list-title'>
                    Модификации
                </p>
                <p className='description__text'>
                    Доступны две модификации продукта, ориентированные на различные источники входных данных:
                </p>
                <ul className='description__list'>
                    <li className='description__list-item'>Phone - обработка речевых данных из телефоного
                        канала;
                    </li>
                    <li className='description__list-item'>Broadcast - обработка речевых данных из
                        медийного (телерадиовещательного) канала.
                    </li>
                </ul>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Системные требования (минимальные)</p>
                <ul className='description__list'>
                    <li className='description__list-item'>
                        CPU Intel Core i7 &ndash; 5820K 3.3 ГГц (6 физических вычислительных ядер);
                    </li>
                    <li className='description__list-item'>
                        ОЗУ 16 ГБ;
                    </li>
                    <li className='description__list-item'>
                        HDD 100 Гб свободного места на жёстком диске.
                    </li>
                </ul>
                <p className='description__text'>
                    Поддерживаемые ОС: Microsoft Windows 7 SP1 или выше либо ОС на основе ядра Linux.
                </p>
                <p className='description__text'>
                    Бесплатно протестировать ПО 3i Speech Transcriptor можно с помощью облачного
                    сервиса: <a href='https://3i-vox.ru/'>3i-vox.ru</a>
                </p>
            </div>

            <div className='description__section'>
                <p className='description__sub-title'>Стоимость</p>
                <p className='description__text'>
                    Стоимость ПО определяется на основе технических требований. Для расчета
                    стоимости <a href='/#contacts'>свяжитесь с нашим менеджером</a>.
                </p>
            </div>
            <div className='description__section'>
                <p className='description__sub-title'>Документация</p>
                <p className='description__text'>
                    Вы можете ознакомиться с подробной документацией на продукт.
                </p>
            </div>
            <a
                className='description__download-link'
                href='/files/3i_Speech_Transcriptor.pdf'
                download
            >
                Скачать документацию
            </a>
        </div>
    )
}
