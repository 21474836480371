import {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import clsx from 'clsx'

import Burger from './Burger'
import chevron from '../icons/chevron-left.svg'

const tabs = {
    '#about': {id: 0, title: 'О компании'},
    '#partners': {id: 1, title: 'Партнеры'},
    '#contacts': {id: 2, title: 'Контакты'}
}

export default function Header () {
    const [buttonState, setButtonState] = useState({activeTabId: -1})
    const [productsLinkActive, setProductsLinkActive] = useState(false)

    const [isDesktop, setDesktop] = useState(window.innerWidth > 1024)

    const location = useLocation()
    const urlParams = location.pathname.split('/')

    useEffect(() => {
        if (window.location.pathname === '/products') {
            setButtonState({activeTabId: -1})
            setProductsLinkActive(true)
        } else {
            const {hash} = window.location
            setButtonState({activeTabId: tabs[hash]?.id || -1})
        }
    }, [])

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1024)
    }

    useEffect(() => {
        window.addEventListener('resize', updateMedia)
        return () => window.removeEventListener('resize', updateMedia)
    })

    return (
        <header className='header'>
            <div className='header__container'>
                { !isDesktop && urlParams[2] ? (
                    <Link className='header__title' to='/products' onClick={() => {
                        setButtonState({activeTabId: -1})
                        setProductsLinkActive(true)
                    }}>
                        <img className='header__title_chevron' alt='' src={chevron}/>
                        <p>Назад</p>
                    </Link>
                )
                    : (
                        <Link to='/' onClick={() => {
                            setButtonState({activeTabId: -1})
                            setProductsLinkActive(false)
                        }}>
                            <p className='header__logo'>DSS Lab</p>
                        </Link>
                    )
                }
                { isDesktop ? (
                    <div className='header__sections'>
                        <Link to='/products' onClick={() => {
                            setButtonState({activeTabId: -1})
                            setProductsLinkActive(true)
                        }
                        }>
                            <button className={
                                clsx('header__section', productsLinkActive && 'header__section_pressed')
                            }>
                                Продукты
                            </button>
                        </Link>
                        { Object.entries(tabs).map(([key, value]) => (
                            <a href={`/${key}`} key={value.id} onClick={() => {
                                setButtonState({activeTabId: value.id})
                                setProductsLinkActive(false)
                            }
                            }>
                                <button className={
                                    clsx('header__section', value.id === buttonState.activeTabId &&
                                        'header__section_pressed'
                                    )
                                }>
                                    {value.title}
                                </button>
                            </a>
                        ))}
                    </div>
                )
                    : <Burger/>
                }
            </div>
        </header>
    )
}
