import Logo3iTech from '../icons/3iLogo.svg'
import SKlogo from '../icons/sk.png'
import FasieLogo from '../icons/fasie-light.png'

export default function Footer () {
    const currentYear = new Date().getFullYear()

    return (
        <footer className='footer'>
            <div className='footer__container'>
                <p className='footer__info'>
                    <div className='footer__partners-logo'>
                        <a
                            href='https://3itech.ru/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img alt='' src={Logo3iTech}/>
                        </a>
                        <a
                            href='https://sk.ru/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img alt='' src={SKlogo}/>
                        </a>
                        <a
                            className='fasie'
                            href='https://fasie.ru/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img alt='' src={FasieLogo}/>
                        </a>
                    </div>
                    <span>
                        © {currentYear} DSS Lab
                    </span>
                </p>
            </div>
        </footer>
    )
}

